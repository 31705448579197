const adminCheck = (isAlert) => {
    if (sessionStorage.getItem("userId") !== "admin" &&
        sessionStorage.getItem("userId") !== "mik3187") {
        isAlert && alert("접근불가능합니다.");
        return false;
    } else {
        return true
    }
}

export default adminCheck;